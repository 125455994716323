import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'link',
        label: 'Dashboards',
        route: '/',
        icon: 'mat:insights',
        routerLinkActiveOptions: { exact: true },
        roles:['super_admin', 'state_admin']
      },
      {
        type: 'dropdown',
        label: 'Master',
        icon: 'mat:view_headline',
        children: [
          {
            type: 'link',
            label: 'Country',
            route: '/master/country',
            roles:['super_admin']
          },
          {
            type: 'link',
            label: 'State',
            route: '/master/state',
            roles:['super_admin']
          },
          {
            type: 'link',
            label: 'District',
            route: '/master/district',
            roles:['super_admin']
          },
          {
            type: 'link',
            label: 'Tehsil',
            route: '/master/tehsil',
            roles:['super_admin']
          },
          {
            type: 'link',
            label: 'Village',
            route: '/master/village',
            roles:['super_admin']
          },
          {
            type: 'link',
            label: 'Local Body',
            route: '/master/local-body',
            roles:['super_admin']
          },
          {
            type: 'link',
            label: 'Ward',
            route: '/master/ward',
            roles:['super_admin']
          }
        ],
        roles:['super_admin']
      },
      {
        type: 'dropdown',
        label: 'Manage User',
        icon: 'mat:people',
        children: [
          {
            type: 'link',
            label: 'User List',
            route: '/manage-user/user-list',
            roles:['super_admin', 'state_admin']
          },
          {
            type: 'link',
            label: 'Add User',
            route: '/manage-user/add-user',
            roles:['super_admin', 'state_admin']
          }
        ],
        roles:['super_admin', 'state_admin']
      }, {
        type: 'dropdown',
        label: 'Members',
        icon: 'mat:people',
        children: [
          {
            type: 'link',
            label: 'Member List',
            route: '/manage-member/list',
            roles:['super_admin', 'state_admin']
          },
          {
            type: 'link',
            label: 'Search Member',
            route: '/manage-member/search',
            roles:['super_admin', 'state_admin']
          }
        ],
        roles:['super_admin', 'state_admin']
      },
      {
        type: 'link',
        label: 'Coordinator List',
        route: '/coordinator/list',
        icon: 'mat:people',
        routerLinkActiveOptions: { exact: true },
        roles:['super_admin', 'state_admin']
      },
      {
        type: 'link',
        label: 'Page',
        route: '/',
        icon: 'mat:list_alt',
        routerLinkActiveOptions: { exact: true },
        roles:['super_admin']
      }, {
        type: 'link',
        label: 'Contact',
        route: '/',
        icon: 'mat:contacts',
        routerLinkActiveOptions: { exact: true },
        roles: ['super_admin']
      }, {
        type: 'link',
        label: 'Export',
        route: '/',
        icon: 'mat:import_export',
        routerLinkActiveOptions: { exact: true },
        roles: ['super_admin']
      }
    ]);
  }
}
