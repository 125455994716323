import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { authGuard } from './shared/auth.guard';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/pages/auth/components/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './pages/pages/auth/components/forgot-password/forgot-password.component'
        ).then((m) => m.ForgotPasswordComponent)
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/dashboards/components/dashboard-analytics/dashboard-analytics.component'
            ).then((m) => m.DashboardAnalyticsComponent)
      },
      {
        path: 'manage-user',
        loadChildren: () => import('./pages/manage-user/manage-user.routes')
      },
      {
        path: 'coordinator',
        loadChildren: () => import('./pages/coordinator/coordinator.routes')
      },
      {
        path: 'manage-member',
        loadChildren: () => import('./pages/manage-member/manage-member.routes')
      },
      {
        path: 'master',
        loadChildren: () => import('./pages/master/master.routes')
      },
      {
        path: 'pages',
        children: [
          {
            path: 'error-404',
            loadComponent: () =>
              import('./pages/pages/errors/error-404/error-404.component').then(
                (m) => m.Error404Component
              )
          },
          {
            path: 'error-500',
            loadComponent: () =>
              import('./pages/pages/errors/error-500/error-500.component').then(
                (m) => m.Error500Component
              )
          }
        ]
      },

      {
        path: '**',
        loadComponent: () =>
          import('./pages/pages/errors/error-404/error-404.component').then(
            (m) => m.Error404Component
          )
      }
    ],
    canActivate: [authGuard]
  }
];
