import { Injectable } from '@angular/core';
import { User } from '../../pages/pages/auth/models/login-response';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  setLocalStorage(key: string, data: any) {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving to local storage', e);
      return null;
    }
  }

  getLocalStorage(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key) || '');
    } catch (e) {
      return null;
    }
  }

  getCurrentUser(): User {
    return this.getLocalStorage('userDetails');
  }


  logout() {
    localStorage.clear();
  }

}
