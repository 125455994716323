<vex-base-layout *ngIf="config$ | async as config">
  <vex-progress-bar></vex-progress-bar>
  <mat-sidenav-container class="vex-layout-sidenav-container">
    <mat-sidenav (closedStart)="onSidenavClosed()" [disableClose]="!!(sidenavDisableClose$ | async)"
                 [fixedInViewport]="!!(sidenavFixedInViewport$ | async)" [mode]="(sidenavMode$ | async) ?? 'side'"
                 [opened]="!!(sidenavOpen$ | async)">
      <vex-sidenav [collapsed]="!!(sidenavCollapsed$ | async)"></vex-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="vex-layout-sidenav-content">
      <vex-toolbar [class.dark]="config.layout === 'vertical'" class="vex-toolbar"></vex-toolbar>
      <main class="vex-layout-content">
        <router-outlet></router-outlet>
      </main>
      <vex-footer *ngIf="config.footer.visible" class="vex-footer"></vex-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</vex-base-layout>
