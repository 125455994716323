import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './services/auth.service';
import { LocalStorageService } from './services/local-storage.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const localStorageService = inject(LocalStorageService);
  let newReq = req.clone();

  if (authService.isAuthenticated()) {
    newReq = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorageService.getCurrentUser().access_token}`
      }

    });
    if (req.body instanceof FormData) {
      newReq = newReq.clone({
        headers: req.headers.delete('Content-Type')
      });
    }
  }
  return next(newReq);
};
