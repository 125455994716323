import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginResponse } from '../../pages/pages/auth/models/login-response';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {


  }

  isAuthenticated(): boolean {
    return !!this.localStorageService.getCurrentUser();
  }

  getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  login(loginRequest: any) {
    return this.http.post<LoginResponse>(`${environment.api_url}login`, loginRequest);
  }
  logout() {
    return this.http.post<LoginResponse>(`${environment.api_url}logout`, {  });
  }

}
